export const navLinks = [

  {
    label: 'Bienvenue',
    pathname: '/',
    hash: '#details',
  },
  {
    label: 'Formation',
    pathname: '/',
    hash: '#formation',
  },
  /*{
    label: 'Expérience',
    pathname: '/',
    hash: '#experience',
  },*/
  {
    label: 'Projets',
    pathname: '/',
    hash: '#projects',
  },

  // {
  //   label: 'Articles',
  //   pathname: '/articles',
  // },
  //{
  //  label: 'Contact',
  //  pathname: '/contact',
  //},
  //{
  //  label: 'Studio',
  //  pathname: '#studio',
  //},
];

export const socialLinks = [
  {
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/in/sebastien-friedberg/',
    icon: 'linkedin',
  },
  {
    label: 'Github',
    url: 'https://github.com/SebastienGt',
    icon: 'github',
  },
];
